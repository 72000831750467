import { HeadingElement, HeadingScopeElement } from "@expediagroup/mojo-protocol-typescript-client";
import * as React from "react";
import { UitkHeading, UitkHeadingProps } from "@egds/react-core/text";
import { mjRenderChildren } from "../MJRenderChildren";

const HeadingLevelContext = React.createContext<number>(1);

const tagMap: Record<number, UitkHeadingProps["tag"]> = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
  6: "h6",
};

const overflowMap: Record<number, UitkHeadingProps["overflow"]> = {
  1: "truncate",
  2: "truncate-2-lines",
  3: "truncate-3-lines",
  4: "truncate-4-lines",
};

export const MJHeadingScope: React.FC<{ element: HeadingScopeElement }> = ({ element }) => {
  const currentValue = React.useContext(HeadingLevelContext);
  const propagatedValue = element.overrideHeadingLevel !== undefined ? element.overrideHeadingLevel : currentValue + 1;

  return (
    <HeadingLevelContext.Provider value={propagatedValue}>
      {mjRenderChildren(element.children)}
    </HeadingLevelContext.Provider>
  );
};

export const MJHeading: React.FC<{ element: HeadingElement }> = ({ element }) => {
  const value = React.useContext(HeadingLevelContext);
  const { size, maxLines, children } = element;

  return (
    <UitkHeading tag={tagMap[value]} size={size ?? (value as any)} overflow={overflowMap[maxLines ?? 0] ?? "wrap"}>
      {mjRenderChildren(children)}
    </UitkHeading>
  );
};
